@import '../../../sass/variables';


.modal-content {
  margin: 0 auto;
  width: auto!important;
  .wrapped-modal-confirm {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    padding: 30px;
    text-align: center;
    font-family: $font-second;
    .modal-header {
      position: absolute;
      top: 0;
      z-index: 1;
      right: 1px;
      border: none;
      button {
        &:focus {
          outline: none; } } }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 27px;
      color: $primery-color;
      margin-bottom: 30px; }
    p {
      font-size: 20px;
      //line-height: 40px
      color: $second-color;
      margin: 0; }
    .button {
      margin-top: 30px; } } }

@media screen and (max-width: 480px) {
  .wrapped-modal-confirm {
    width: 250px; }
  .wrapped-modal-confirm h3 {
    font-size: 24px;
    margin-bottom: 15px; }
  .wrapped-modal-confirm p {
    font-size: 14px; } }
