@import '../../sass/variables';

.login-bg {
  height: 100vh;
  width: 100%;
  .menu-form {
    @extend %main-content;
    width: 100%;
    align-items: center;
    .form-consult {
      @extend %main-form;
      padding: 45px 30px;
      width: auto;

      h5 {
        font-weight: bold;
        font-size: 20px;
        line-height: 27px; }
      input {
        border-radius: 35px;
        padding: 32px 24px; }
      button {
        padding: 20px; } } } }

.table-list {
  width: 100%;
  position: relative;
  table {
    border-collapse: collapse;
    border: 1px solid #8e8d8e;
    width: 100%;
    font-size: 14px;

    th, td {
      text-align: left;
      padding: 8px;
      border: 1px solid #8e8d8e;


      tr:nth-child(even) {
        background-color: #f2f2f2; }
      tr:hover {
        background-color: #f5f5f5; } } } }

.menu-form-cr {
  width: 100%;
  .header-btn {
    button {
      padding: 14px 20px;
      margin: 8px 0;
      font-size: 14px;
      width: 150px; } }


  .form-consult {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    width: 100%;

    input, select {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0 4px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box; }


    button {
      width: 100%;
      background-color: $primery-color;
      color: white;
      padding: 14px 20px;
      margin: 8px 0;
      border: none;
      border-radius: 4px;
      cursor: pointer; }


    button:hover {
      background-color: #0f3ba9; }


    .error-text {
      font-size: 10px;
      color: red;
      margin-left: 20px; } } }
