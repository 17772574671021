@import '../../../sass/variables';
.create-food-wrapped {
  padding: 20px; }

.form-consult {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 100%;


  input, select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0 4px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; }


  button {
    width: 100%;
    background-color: $primery-color;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer; }


  button:hover {
    background-color: #0f3ba9; }

  .section-wrapped {
    border: 1px solid grey;
    padding: 10px;
    h4 {
      text-align: center;
      color: $primery-color; }
    span {
      display: inline-block; }
    .article-name {
      font-size: 18px; } } }

.modal-90w {
  width: 90%;
  max-width: none!important;
  .modal-content {
    max-width: none!important; } }



.span-padding-15 {
  padding: 15px;
 }  //border: 1px solid grey
