$font-stack: 'Open Sans', sans-serif;
$font-second: 'Roboto', sans-serif;
$primery-color: #1157cc;
$blue: $primery-color;
$second-color: #0E1658;
$dark-blue: $second-color;
$third-color: #8E8D8E;
$gray: #CBCCDA;
$white: #fff;
$text: #2D2D2D;

.container {
  max-width: 1360px; }

%main-p {
  font-size: 16px;
  color: $second-color;
  line-height: 32px;
  font-family: $font-stack; }
%main-bg {
  background: no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative; }
%main-content {
  display: flex;
  flex-direction: column;
  justify-content: center; }
%main-form {
  //width: 324px
  //height: 457px
  background: #F8F9FF;
  border: 1px solid #1157CC;
  box-sizing: border-box;
  box-shadow: 15px 15px 35px rgba(0, 19, 88, 0.06);
  padding: 24px 20px;
  border-radius: 20px;
  h5 {
    text-align: center;
    font: 15px/22px $font-second;
    font-weight: 500;
    color: $second-color; }
  input {
    margin: 10px 0;
    border-radius: 25px;
    //border-color: $primery-color
    padding: 20px 20px 24px;
    font: 14px/14px $font-stack;
    font-weight: bold;
    &:first-child {
      margin-top: 20px; } }
  input:focus {
    color: $second-color; } }
